import { storage } from './firebase';
import firebase from 'firebase/app';
import ExperienceModel from '../models/ExperienceModel';
import UploadVideoFileModel from 'src/models/UploadVideoFileModel';
import UploadImageFileModel from 'src/models/UploadImageFileModel';
import { v4 as uuidv4 } from 'uuid';
import Experience, {
  TrackableSpec,
  VideoSpec
} from 'src/models/PlayerExperience';

const uploadState = (
  snapshot: firebase.storage.UploadTaskSnapshot,
  callback?: (success: boolean) => any
) => {
  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  console.log('Upload is ' + progress + '% done');
  snapshot.ref.updateMetadata({ cacheControl: 'no-store, max-age=0' });
  switch (snapshot.state) {
    case firebase.storage.TaskState.PAUSED:
      console.log('Upload is paused');
      break;

    case firebase.storage.TaskState.RUNNING:
      console.log('Upload is running');
      break;

    case firebase.storage.TaskState.SUCCESS:
      console.log('Success');
      callback?.(true);
      break;
  }
};

const mapExperienceModelToPlayerExperience = (
  experience: ExperienceModel
): Experience => {
  // map experience to fit player
  experience.videos.forEach((video: UploadVideoFileModel) => {
    if (video.staysVisible || video.three60) {
      experience.trackables.forEach((trackable) => {
        if (trackable.id === video.selectedTrackableId) {
          trackable.manual_close = true;
        }
      });
    }
    // TODO: this is a bit hacky and I think the filters should not be called video and the model needs to change but it should do for now.
    if (video.filterType === '360° Image') {
      if (!experience.images) {
        experience.images = [];
      }
      const index = experience.images.push(video) - 1;
      experience.trackables.forEach((trackable) => {
        if (trackable.id === video.selectedTrackableId) {
          trackable.image = index;
          trackable.video = undefined;
        }
      });
    }
    if (video.transparentColor) {
      video.transparency = {
        color: video.transparentColorCode,
        similarity: 0.65, // default values for now. UI for these comes later
        smoothness: 0.03 // default values for now. UI for these comes later
      };
    }
  });
  // fix video layers
  experience.trackables.forEach((trackable: UploadImageFileModel) => {
    (trackable as any).layers = undefined;
    let videoCount = 0;
    experience.videos.forEach(
      (video: UploadVideoFileModel, videoIndex: number) => {
        if (video.selectedTrackableId === trackable.id) {
          if (videoCount === 0) {
            trackable.video = videoIndex;
          } else {
            if (!(trackable as any).layers) {
              (trackable as any).layers = [];
            }
            (trackable as any).layers.push({
              video: videoIndex,
              position: { x: 0, y: 0, z: 10 * (videoCount + 1) }
            });
          }
          videoCount++;
        }
      }
    );
    videoCount = 0;
  });

  console.log('experience', experience);

  const playerExperience: Experience = {
    type: experience.type,
    title: experience.title,
    img1: experience.img1,
    brandColor: experience.brandColor,
    envMap: '',
    id: experience.uuid,
    img2: '',
    trackables: experience.trackables.map<TrackableSpec>((trackable) => {
      return {
        url: trackable.url,
        name: trackable.name,
        video: trackable.video,
        image: trackable.image,
        manual_close: trackable.manual_close || false,
        layers: (trackable as any).layers,
        center: false
      };
    }),
    videos: experience.videos.map<VideoSpec>((video) => {
      return {
        url: video.url,
        three60: video.three60,
        rotation: video.rotation,
        loops: video.loops,
        muted: video.muted,
        name: video.videoFileName,
        transparency: video.transparency
      };
    }),
    loading: {},
    scanTarget: '',
    portalUrl: '',
    images: experience.images,
    whiteLabel: false, // needs to be set according to subscription
    cta: undefined,
    startDate: experience.startdate,
    duration: experience.duration,
    startButtonText: ''
  };

  return playerExperience;
};

const uploadPlayerExperience = (
  experience: ExperienceModel,
  callback: (success: boolean, fileName: string) => void
) => {
  const playerExperience: Experience = mapExperienceModelToPlayerExperience(
    experience
  );
  //Create file from experience
  console.log('player experience', playerExperience);
  const experienceBlob = new Blob([JSON.stringify(playerExperience)], {
    type: 'application/json'
  });

  // Upload the player model to be able to show WebAR content
  const liveExperiencePath = `${process.env.REACT_APP_Experience_Store}/${experience.uuid}`;
  const uploadTaskPlayer = storage
    .ref()
    .child(liveExperiencePath)
    .put(experienceBlob);

  uploadTaskPlayer
    .then((snapshot) =>
      uploadState(snapshot, (state) => {
        // TODO: this needs to become the new storage path. We might want to return the editor file storage path as well.
        // Make it an object in that case.
        callback(state, liveExperiencePath);
      })
    )
    .catch((error: any) => {
      console.error(error);
      callback(false, '');
    });
};

const uploadExperience = function (
  experience: ExperienceModel,
  callback: (success: boolean, fileName: string) => void
) {
  if (experience.filePath) {
    // backwards compatibility: Maybe there is no UUID set
    experience.uuid = experience.uuid || uuidv4();
    //Create file from experience
    console.log('experience', experience);
    const experienceBlob = new Blob([JSON.stringify(experience)], {
      type: 'application/json'
    });

    const filePath = experience.filePath;

    // Upload the editor model for the user to come back and edit his work
    const uploadTask = storage.ref().child(filePath).put(experienceBlob);

    uploadTask.then(uploadState).catch((error: any) => {
      console.error(error);
      callback(false, '');
    });
    uploadPlayerExperience(experience, callback);
  } else {
    callback(false, 'Exeperience must have a file path.');
  }
};

const mapItemsUrls = async (items: firebase.storage.Reference[]) => {
  if (items && items.length > 0) {
    return Promise.all(
      items.map(async (item) => {
        return await getExperienceJsonData(await item.getDownloadURL());
      })
    );
  } else {
    return [];
  }
};

const getExperiencesByEmail = async (email: string) => {
  try {
    const storagepath = 'exp/' + email;
    const listTask = storage.ref().child(storagepath);

    const response = await listTask.list();

    if (response) {
      if (response.items) {
        const experiences: ExperienceModel[] = (
          await mapItemsUrls(response.items)
        ).filter((e) => e !== undefined) as any;
        return {
          success: true,
          experiences: experiences,
          message: ''
        };
      }
    }
  } catch (error) {
    console.log('error', error);
    return {
      success: false,
      experiences: [],
      message: 'There was a problem retrieving your experiences.'
    };
  }
};

const getExperienceByName = (
  fileName: string,
  callBack: (success: boolean, message: string, experienceUrl: string) => void
) => {
  const storagepath = fileName;
  const getTask = storage.ref().child(storagepath).getDownloadURL();

  getTask
    .then(function (response: any) {
      const progress = (response.bytesTransferred / response.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (response.state) {
        case firebase.storage.TaskState.PAUSED:
          console.log('Upload is paused');
          break;

        case firebase.storage.TaskState.RUNNING:
          console.log('Upload is running');
          break;

        case firebase.storage.TaskState.SUCCESS:
          console.log('Success');
          response.ref.getDownloadURL().then(function (downloadURL: string) {
            console.log('addVideo = () =>ap', downloadURL);
            callBack(true, '', downloadURL);
          });
          break;
      }
    })
    .catch((error: any) => {
      console.error(error);
      callBack(false, 'There was an error uploading your file.', '');
    });
};

const getExperienceJsonData = async (urlIn: string) => {
  const downloadUrl = urlIn;

  if (downloadUrl.length > 0 && !downloadUrl.includes('companyLogo')) {
    const experienceResponse = await fetch(downloadUrl);
    if (experienceResponse) {
      if (experienceResponse.status === 200) {
        console.log(experienceResponse);
        const data = (await experienceResponse.json()) as ExperienceModel;
        return data;
      } else {
        console.log(
          'error',
          experienceResponse.status,
          experienceResponse.statusText,
          downloadUrl
        );
        throw new Error('HTTP error status: ' + experienceResponse.status);
      }
    }
  }
};

export {
  getExperiencesByEmail,
  getExperienceByName,
  uploadExperience,
  getExperienceJsonData
};

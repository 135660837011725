import React, { useEffect, useState } from 'react';

import { StorageService } from 'src/services/StorageService';
import { Constants } from 'src/models/Constants';
import firebase from 'firebase/app';
import "firebase/auth";
import UserModel from 'src/models/UserModel';
import logo from '../../assets/images/logo/perkLab_logo.png';

import './styles.css';

interface IProps {
  user?: UserModel;
}

export const Header: React.FC<IProps> = ({ user }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const [userName, setUserName] = useState("");

  const logout = () => {
    firebase.auth().signOut();
    StorageService.Clear(Constants.ExperienceListKey);
    StorageService.Clear(Constants.UserKey);
  };

  useEffect(() => {
    const userFromStorage = localStorage.getItem('picalive.user');
    
    if(userFromStorage !== null && userFromStorage !== "") {
      const userObj = JSON.parse(userFromStorage);

      if(userObj.loginProvider === "facebook.com") {
        let atPosition = userObj.email.split('').indexOf("@");
        let userEmailName = userObj.email.substring(0, atPosition);

        setUserName(userEmailName);
      }
    }
  }, [])

  return (
    <header className='headerContainer'>
        <a href='/'>
        <img src={logo} alt='perkLAB' style={{ maxWidth: "300px" }} />
        </a>
      
      <aside>
        {user && user.name !== 'Guest' ? (
          <>
            {userName !== "" ? (
              <p className='helloUser'>Hello, {userName}</p>  
            ) : (
              <p className='helloUser'>Hello, {user.firstName}</p>
            )}
            <a href='/'>
              <button className='logInWithFacebook'>Home</button>
            </a>
            <a href="mailto:info@tripod-digital.co.nz">
              <button className='contactUs'>
                Contact Us
              </button>
            </a>
            <a href='/'>
              <button onClick={logout} className='logInWithGoogle'>
                Logout
              </button>
            </a>
          </>
        ) : (
          <>
            <a href="mailto:info@tripod-digital.co.nz">
              <button className='contactUs'>
                Contact Us
              </button>
            </a>
            <a href="/login">
              <button className="headerLoginButton">Login</button>
            </a>
          </>
        )}
      </aside>

      <div
        className={`burgerContainer ${menuOpen ? 'open' : ''} `}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <div className='burger'></div>
      </div>

      <div className={`sideMenu ${menuOpen ? 'open' : ''} `}>
        <div className='sideMenuContainer'>
          <div className='sideMenuheaderLogo'>
            <a href='/'>
              <img src={logo} alt='perkLAB' style={{ maxWidth: "300px" }} />
            </a>
          </div>
          <div className='sideMenubuttonsContainer'>
            {user && user.name !== 'Guest' ? (
              <>
                <p className='helloUser'>Hello, {user.name}</p>
                <a href='/'>
                  <button className='logInWithFacebook'>Home</button>
                </a>
                <a href="mailto:info@tripod-digital.co.nz">
                  <button className='contactUs'>
                    Contact Us
                  </button>
                </a>
                <a href='/'>
                  <button onClick={logout} className='logInWithGoogle'>
                    Logout
                  </button>
                </a>
              </>
            ) : (
              <>
                <a href="/login">
                  <button className="headerLoginButton">Login</button>
                </a>
                <a href="mailto:info@tripod-digital.co.nz">
                  <button className='contactUs'>
                    Contact Us
                  </button>
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;


import { StorageService } from './StorageService';
import { Constants } from 'src/models/Constants';
import UserModel from 'src/models/UserModel';
import { useState, useEffect } from 'react';
// import firebase from 'firebase/app';
import { auth } from './firebase';

// const auth = firebase.auth();

const isUserLogged = function () {
  const userJson = StorageService.Get(Constants.UserKey);

  if (userJson) {
    const userModel = JSON.parse(userJson) as UserModel;
    if (userModel) {
      if (userModel.name === '') {
        const atPosition = userModel.email.split('').indexOf('@');
        const userName = userModel.email.substring(0, atPosition);
        userModel.name = userName;
        userModel.firstName = userName;
      }
      return userModel;
    }
  }

  return null;
};

interface AuthState {
  isSignedIn: boolean;
  pending: boolean;
  user: null | UserModel;
}

export function useAuth() {
  const [authState, setAuthState] = useState<AuthState>({
    isSignedIn: false,
    pending: true,
    user: null
  });

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      if (user) {
        let cacheUserInfo = isUserLogged();
        if (!cacheUserInfo) {
          const userModel = new UserModel();

          const atPosition = user.email?.split('').indexOf('@');
          const userName = user.email?.substring(0, atPosition);

          userModel.email = user.email || '';
          userModel.lastName = '';

          if (userName !== undefined) {
            userModel.name = user.displayName || userName;
            userModel.firstName = user.displayName || userName;
          } else {
            userModel.name = user.displayName || '';
            userModel.firstName = user.displayName || '';
          }

          userModel.id = user.uid;
          userModel.loginProvider = user.providerData[0]?.providerId || '';

          const userJson = JSON.stringify(userModel);
          StorageService.Store(Constants.UserKey, userJson);

          cacheUserInfo = isUserLogged();
        }
        if (cacheUserInfo) cacheUserInfo.id = user.uid;

        // TODO: add check if user is logged in and already in cache. If not build new user and store in cache.

        user.getIdToken(true).then(() => {
          user.getIdTokenResult(true).then((token) => {
            cacheUserInfo!.stripeRole = token.claims.stripeRole;
          });
        });
        setAuthState({
          user: cacheUserInfo,
          pending: false,
          isSignedIn: !!user
        });
      } else {
        setAuthState({ user: null, pending: false, isSignedIn: !!user });

        //COMMENT: This is a test to log as guest if there is no user
        // const guestUser = {
        //   id: "H7lEx9Y1hIa6aaCcazjDO8wWWkl1",
        //   name: "Guest",
        //   firstName: "Guest",
        //   lastName: "NoUser",
        //   email: "guest@email.com",
        //   loginProvider: "email",
        //   accessToken: "H7lEx9Y1hIa6aaCcazjDO8wWWkl1",
        //   firebaseUser: null
        // };

        // guestUser.id = "H7lEx9Y1hIa6aaCcazjDO8wWWkl1"
        // guestUser.name = "Guest";
        // guestUser.firstName = "Guest";
        // guestUser.lastName = "NoUser";
        // guestUser.email = "guest@email.com";
        // guestUser.loginProvider = "email";
        // guestUser.accessToken = "H7lEx9Y1hIa6aaCcazjDO8wWWkl1";
        // guestUser.firebaseUser = null;

        // const userJson = JSON.stringify(guestUser);
        // StorageService.Store(Constants.UserKey, userJson);

        // setAuthState({ user: guestUser, pending: false, isSignedIn: !!user })
      }
    });
    return () => unregisterAuthObserver();
  }, []);

  // return { auth, ...authState }
  return { auth, ...authState };
}

import React, { useContext, useEffect, useState } from 'react';
import { ExperienceContext } from 'src/contexts/ExperienceContext';
import UserModel from 'src/models/UserModel';

import * as queryString from 'query-string';

import successAR from '../../assets/images/sucessAR.png';
import { Spinner } from 'react-bootstrap';
import { db } from 'src/services/firebase';
import './Success.css';
import { Constants } from 'src/models/Constants';
import ExperienceModel from 'src/models/ExperienceModel';

import {usePreview} from 'src/components/success/usePreview';
import ExperienceStep from '../experience-step/ExperienceStep';

interface IProps {
  user?: UserModel;
  setUserModels: (user: UserModel) => void;
  experience?: ExperienceModel;
}

const Success: React.FC<IProps> = ({ user, experience }) => {
  const {
    qrCodeImg,
    handleGoHome,
    saveExperience,
    setOriginalExperienceName,
    savingExperienceSuccess,
    freePlan,
    saveExperienceToLocalStore
  } = useContext(ExperienceContext);

  const preview = usePreview(experience);

  const [error, setError] = useState<boolean>(false);

  const [selectedExperience] = useState<ExperienceModel>(experience || (JSON.parse(localStorage.getItem('experience') || "")));

  useEffect(() => {

    const storageExperiences = localStorage.getItem(Constants.ExperienceListKey);
    const storageOriginalName = localStorage.getItem('originalExperienceName');
    const storageIsEditing = localStorage.getItem('isEditing');

    


    if (selectedExperience !== null ) {

    

      if(storageIsEditing === "true") {
        selectedExperience.startdate = new Date().getTime();
        saveExperience(selectedExperience);
        return;
      }

      

      if (freePlan) {
        selectedExperience.duration = 1;
        selectedExperience.startdate = new Date().getTime();
        saveExperienceToLocalStore(selectedExperience);
        saveExperience(selectedExperience);
        return;
      }

      

      if (storageOriginalName !== null) {
        setOriginalExperienceName(storageOriginalName);
      }

           

      if (!user) {
        return;
      }

      // If is a 'basic' user with less than 5 experiences, then allow to save experience
      if (storageExperiences !== null && storageExperiences !== '' && storageExperiences.length <=5) {
        // const storageExperiencesObject = JSON.parse(storageExperiences);

        if (user.stripeRole === "basic" && user.email !== "guest@email.com") {
          saveExperience(selectedExperience);
          return;
        }
      }
      
      if(storageExperiences !== "" && storageExperiences !== null) {
        const listOfExp = JSON.parse(storageExperiences);
        
        if (
          user.stripeRole === "basic" &&
          listOfExp.length <=5
        ) {
          saveExperience(selectedExperience);
          return;
        }
      }

      // Check if the checkout experience title is the same as the one in the store
      // 1 read the checkout session and read the metadata and the experience title in the metadata
      // 2 read checkoutSessionId from URL parameters
      const parsed = queryString.parse(window.location.search);
      const sessionId = parsed.session_id;

      db.collection('customers')
        .doc(user.id)
        .collection('checkout_sessions')
        .where('sessionId', '==', sessionId)
        .get()
        .then(docRef => {
          if (docRef.empty) {
            setError(true);
          }
          docRef.forEach(function (productDoc) {
            if (
              productDoc.data().metadata.experienceId ===
              selectedExperience.title
            ) {
              // check if experience title is the same as storageExperienceObject.title
              // IF NOT SHOW ERROR
              saveExperience(selectedExperience);
            } else {
              setError(true);
            }
          });
        });
    }
    // We only want this to run once
    // eslint-disable-next-line
  }, [user]);

  if (error) {
    return (
      <div className='successContainer route'>
        <aside>
          <h1>
            <strong>Error</strong> <br /> There was an error saving your
            experience
          </h1>
          <p>Please contact support</p>
          <div className='successButtonsContainer' style={{ width: 'unset' }}>
            <button
              onClick={() => {
                window.open('mailto:info@tripod-digital.co.nz');
              }}
            >
              SUPPORT
            </button>
          </div>
        </aside>
      </div>
    );
  } else {
    return (
      <div className='successContainer route'>
        <img src={successAR} alt='Success AR' />
        {!qrCodeImg || !savingExperienceSuccess ? (
          <aside>
            <h1>
              <strong>Hold tight!</strong> <br /> We are saving your experience!
            </h1>
            <Spinner className='spinnerCircle' animation='border' role='status'>
              <span className='sr-only'>Assembling your epxeriences</span>
            </Spinner>
          </aside>
        ) : (
          <aside>
            <ExperienceStep/>
            <h1>
              <strong>Success!</strong> Your experience is now live!
            </h1>
            <p>
              Scan the QR Code below, or click on 'view experience' to see in action!
            </p>

            {qrCodeImg.length > 0 && (
              <a href={qrCodeImg} download>
                <img src={qrCodeImg} alt='QR CODE' />
              </a>
            )}

            <h3>Download QR Code!</h3>

            <h3>(Don’t you just love it when a plan comes together?)</h3>

            <h2>Now, go forth and share your glorious creation with the world!</h2>

            <div className='successButtonsContainer'>
              <button onClick={preview}>VIEW</button>
              <button onClick={handleGoHome} className='homeButton'>
                HOME
              </button>
            </div>

            <p>{`This experience will be live for ${selectedExperience.duration} ${selectedExperience.duration > 1 ? 'days' : 'day'
              }!`}</p>
          </aside>
        )}
      </div>
    );
  }
};

export default Success;

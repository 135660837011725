import React, { useEffect, useState } from 'react';
import ExperienceModel from 'src/models/ExperienceModel';
import UserModel from 'src/models/UserModel';

import '../App.css';
import '../pages/styles/error-message.css';
import CreateExperience from './create-experience';
import { useParams } from 'react-router';
import { Constants } from 'src/models/Constants';

interface IProps {
  user?: UserModel;
  setUserModels: (user: UserModel) => void;
  experience?: ExperienceModel | undefined;
}

export const LoadExperience: React.FC<IProps> = ({ user, setUserModels, experience }) => {
  const query = useParams < { id:string }>();
  const storageExperience = localStorage.getItem(Constants.ExperienceListKey)
  const storageLocalExperience = localStorage.getItem("experience");
  const [selectedExperience] = useState(() => {

    if(storageLocalExperience !== null && storageLocalExperience !== "") {
      return JSON.parse(storageLocalExperience);
    }

    if (query.id && storageExperience !== null && storageExperience !== "") {
      const localStoreExpList: ExperienceModel[] = JSON.parse(storageExperience || "");

      if (experience || localStoreExpList.find(exp => exp.uuid === query.id)) {
        const selectedExperience = experience || localStoreExpList.find(exp => exp.uuid === query.id)
        localStorage.setItem('originalExperienceName', selectedExperience!.title);
        localStorage.setItem('isEditing', "true");
        return selectedExperience
      }
      else {
        return new ExperienceModel()
      }
    } else {
      return new ExperienceModel()
    }
  })

  useEffect(() => {

    if (!user || user.email === "") {     

      const guestUser = new UserModel();

      guestUser.id = "H7lEx9Y1hIa6aaCcazjDO8wWWkl1"
      guestUser.name = "Guest";
      guestUser.firstName = "Guest";
      guestUser.lastName = "NoUser";
      guestUser.email = "guest@email.com";
      guestUser.loginProvider = "email";
      guestUser.accessToken = "H7lEx9Y1hIa6aaCcazjDO8wWWkl1";
      guestUser.firebaseUser = null;
      setUserModels(guestUser);
    }
  }, [
    setUserModels, user
  ])
  return (
    <>
      <div>
        {selectedExperience && <CreateExperience user={user!} experience={selectedExperience!} />}
      </div>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import './App.css';
import { Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { LoadExperience } from './pages/load-experience';
import { ListExperiences } from './pages/list-experiences';

import { ExperienceProvider } from './contexts/ExperienceContext';

import { useAuth } from './services/authenticationService';
import UserModel from './models/UserModel';
import Header from './components/header';
import LandingPage from './components/landing-page';
import Footer from './components/footer';
// import { } from './services/firebase';

import { ToastContainer } from 'react-toastify';
import LogginPageMain from './components/loggin-page-main';
import Success from './components/success';

import { ModalProvider } from './contexts/ModalContext';
import ExperienceModel from './models/ExperienceModel';

if (process.env.NODE_ENV === "production") {
  console.log = () => {
    // forward logs to nothing to not spill secrets in production
  }
  console.warn = () => {
    // forward logs to nothing to not spill secrets in production
  }
}

const App: React.FC = () => {

  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userModel, setUserModel] = useState<UserModel>();
  const [experience, setExperience] = useState<ExperienceModel | undefined>();

  const auth = useAuth();

  // useEffect(() => {
  //   localStorage.setItem('experience', '');
  //   localStorage.setItem('qrCodeResult', '');
  //   localStorage.setItem('step', 'success');
  //   localStorage.setItem('step', 'target-image');
  //   localStorage.setItem('isEditing', '');
  //   localStorage.setItem('originalExperienceName', '');
  //   localStorage.setItem('products', "");
  // }, [])

  useEffect(() => {
    const user = auth.user;

    if(user?.email !== "guest@email.com") {
      setUserLoggedIn(auth.isSignedIn)
    }

    localStorage.setItem('products', "")

    if (user) {
      setUserModel(user);
      setTimeout(() => {
        localStorage.setItem("picalive.user", JSON.stringify(user));
      }, 2000)
    }

  }, [
    auth
  ])

  return (
    <div className="App" >

      <ExperienceProvider user={userModel} setUserModels={setUserModel}>
        <ModalProvider>
          <ToastContainer />
          {userLoggedIn ? (
            <>
              <BrowserRouter>
                <Header user={userModel} />
                <Route exact path='/edit-experience/:id' component={
                  () => <LoadExperience user={userModel} setUserModels={setUserModel} experience={experience}/>
                } />

                <Route exact path='/create-experience' component={
                  () => <LoadExperience user={userModel} setUserModels={setUserModel} />
                } />

                <Route exact path='/create-experience/success' component={
                  () => <Success user={userModel} setUserModels={setUserModel} />
                } />

                <Route exact path='/login' component={
                  () => <LogginPageMain />
                } />

                <Route exact path='/' component={
                  () => <ListExperiences user={userModel} setExperience={setExperience}/>
                } />

              </BrowserRouter>
            </>
          ) : (null)}

          {!userLoggedIn ? (
            <>
              <BrowserRouter>
                <Header user={userModel} />
                <Route exact path='/create-experience' component={() => <LoadExperience user={userModel} setUserModels={setUserModel} />} />
                <Route exact path='/login' component={() => <LogginPageMain />} />

                <Route exact path='/create-experience/success' component={
                  () => <Success user={userModel} setUserModels={setUserModel} />
                } />

                <Route exact path='/' component={() => (<LandingPage />)} />
              </BrowserRouter>
            </>
          ) : (null)}
          <Footer />
        </ModalProvider>
      </ExperienceProvider>

    </div>
  );
}

export default App;

import React, { useContext } from 'react';
import { ExperienceContext } from 'src/contexts/ExperienceContext';
import QRCodeComponent from '../qrCode';
import { iconColor } from 'src/config';
import './ExperienceNameInput.css';

interface IProps {
    errorMessage: string;  
    experienceName: string;
    handleExperienceNameChange: (e: any) => void;
}
  
const ExperienceNameInput: React.FC<IProps> = ({ handleExperienceNameChange }) => {

    const { errorMessage, experienceName, experience } = useContext(ExperienceContext);
      
    return (
        <div className="experienceNameContainer">
            <div className="experienceNameInputContainer">
            <input
                className="inputExperienceName"
                type="text"
                placeholder="Enter Experience Name"
                name="name"
                value={experienceName}
                onChange={handleExperienceNameChange}
            />
            <svg width="23" height="23" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 14.2525V18.0025H3.75L14.81 6.9425L11.06 3.1925L0 14.2525ZM17.71 4.0425C18.1 3.6525 18.1 3.0225 17.71 2.6325L15.37 0.2925C14.98 -0.0975 14.35 -0.0975 13.96 0.2925L12.13 2.1225L15.88 5.8725L17.71 4.0425V4.0425Z" fill={iconColor} />
            </svg>
          <QRCodeComponent expId={experience.uuid} />
            </div>
            {(!experienceName || experienceName.length < 3) && (<p style={{ color: 'red'}}>(Your chosen name needs to have a minimum of 3 characters)</p>)}
            {experienceName.length > 2 && errorMessage && (<p style={{ color: 'red' }}>{errorMessage}</p>)}
      </div>
    )
}

export default ExperienceNameInput;
import React, { useContext } from 'react'
import { ExperienceContext } from 'src/contexts/ExperienceContext';

// import perkLab_text from '../../assets/perkLab_text.png';
import perkLab_phone from '../../assets/perkLab_phone-crop.gif';


import './styles.css';

export default function LandingPage() {

    const { deleteLocalStore } = useContext(ExperienceContext);

  return (
    <>
      <div className="lpContainer">
        <ul>
          <li>1 - CHOOSE TARGET IMAGE</li>
          <li>2 - USE DEVICE TO UPLOAD AR FILTER</li>
          <li>3 - PUBLISH AR LINK OR QR CODE</li>
          <li>4 - ENJOY AND SHARE!</li>
        </ul>
        <img src={perkLab_phone} alt="Perk Lab phone" style={{ width: "100%", maxWidth: "450px", marginLeft: "0px", marginTop: "40px", marginBottom: "60px" }} />
      </div>
                 <a href="/create-experience">
                    <button onClick={deleteLocalStore}>START CREATING</button>
                 </a>
    </>
    )
}
